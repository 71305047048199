import React, { Component } from 'react';
import mediaIcon from '../img/icons/menu-media.png';
import $ from 'jquery';

class Menu extends Component {

    componentDidMount() {

        $(".icon").click(function () {
            $(" ul.menu-click").slideToggle("slow", function () {
                // Animation complete.
            });
        });
    }

    render() {
        return (
            <React.Fragment>
                <section class="menu-media">
                    <div class="menu-content">
                        <div class="icon"><a><img src={mediaIcon} /></a></div>
                    </div>
                </section>

                <ul class="menu-click">
                    <a href="#home"><li href="#home">HOME</li></a>
                    <a href="#about-us"><li href="#about-us">ABOUT ME</li></a>
                    <a href="#portfolio"><li href="#portfolio">PORTFOLIO</li></a>
                    <a href="#contact"><li href="#contact">CONTACT</li></a>
                </ul>

                <section class="menu">
                    <div class="menu-content">
                        <ul id="menu">
                            <li><a href="#home">HOME</a></li>
                            <li><a href="#about-us">ABOUT ME</a></li>
                            <li><a href="#portfolio">PORTFOLIO</a></li>
                            <li><a href="#contact">CONTACT</a></li>
                        </ul>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default Menu;