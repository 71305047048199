import React, { Component } from "react";
import $ from 'jquery';

import img1 from "../img/portfolio/1.png";
import img2 from "../img/portfolio/2.png";
import img3 from "../img/portfolio/3.png";
import img4 from "../img/portfolio/4.png";
import img5 from "../img/portfolio/5.png";
import img6 from "../img/portfolio/6.png";
import img7 from "../img/portfolio/7.png";
import img8 from "../img/portfolio/8.png";
import img9 from "../img/portfolio/9.png";
import img10 from "../img/portfolio/10.png";
import img11 from "../img/portfolio/11.png";

class Portfolio extends Component {

    componentDidMount() {
        $('.grid .text').hover(
            function () {
                $(this).animate({ opacity: '1' });
            },
            function () {
                $(this).animate({ opacity: '0' });
            }
        );

        // if ($(window).width() > 1023) {

        //     const tiles = $("p, h1, h2, h3, .column-one, .column-two, .column-three, .start-page .content .text, hr, .grid li, .contact .content .form, .contact .content .contact-text ").fadeTo(0, 0);

        //     $(window).scroll(function (d, h) {
        //         tiles.each(function (i) {
        //             const a = $(this).offset().top + $(this).height();
        //             const b = $(window).scrollTop() + $(window).height();
        //             if (a < b) $(this).fadeTo(1000, 1);
        //         });
        //     });

        // }
        // else {
        // }
    }

    render() {
        return (
            <React.Fragment>
                <section class="portfolio" id="portfolio">
                    <div class="portfolio-margin">

                        <h1>PORTFOLIO</h1>
                        <hr />

                        <ul class="grid">

                            <li>
                                <a target="_blank" href="https://itunes.apple.com/us/app/the-glass-files/id1227323636?ls=1&mt=8">
                                    <img src={img1} alt="Portfolio item" />
                                    <div class="text">
                                        <p>THE GLASS FILES</p>
                                        <p class="description">iOS, Swift 4.2</p>
                                    </div>
                                </a>
                            </li>

                            <li>
                                <a>
                                    <img src={img2} alt="Portfolio item" />
                                    <div class="text">
                                        <p>SLUICY</p>
                                        <p class="description">iOS, Swift 3.0</p>
                                    </div>
                                </a>
                            </li>

                            <li>
                                <a>
                                    <img src={img3} alt="Portfolio item" />
                                    <div class="text">
                                        <p>NERDS</p>
                                        <p class="description">iOS, Objective-C, Multiplayer</p>
                                    </div>
                                </a>
                            </li>

                            <li>
                                <a target="_blank" href="https://itunes.apple.com/lb/app/escape-from-paradise/id737806558?mt=8">
                                    <img src={img4} alt="Portfolio item" />
                                    <div class="text">
                                        <p>ESCAPRE FROM PARADISE</p>
                                        <p class="description">Unity, Mobile, Level Editor, SQLite</p>
                                    </div>
                                </a>
                            </li>


                            <li>
                                <a target="_blank" href="https://itunes.apple.com/lb/app/scrab-it/id661176587">
                                    <img src={img5} alt="Portfolio item" />
                                    <div class="text">
                                        <p>SCRAB IT</p>
                                        <p class="description">Unity, Mobile, Words Game</p>
                                    </div>
                                </a>
                            </li>

                            <li>
                                <a target="_blank" href="https://itunes.apple.com/lb/app/captain-oil/id594546119?mt=8">
                                    <img src={img6} alt="Portfolio item" />
                                    <div class="text">
                                        <p>CAPTAIN OIL</p>
                                        <p class="description">Unity, Mobile, Level Editor, SQLite</p>
                                    </div>
                                </a>
                            </li>

                            <li>
                                <a target="_blank" href="https://itunes.apple.com/app/id721567521?mt=8&ign-mpt=uo%3D4">
                                    <img src={img7} alt="Portfolio item" />
                                    <div class="text">
                                        <p>RUN CAMEL RUN</p>
                                        <p class="description">Unity, Infinite 2D Scroller</p>
                                    </div>
                                </a>
                            </li>

                            <li>
                                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.obsess.vbhome&hl=en_US">
                                    <img src={img9} alt="Portfolio item" />
                                    <div class="text">
                                        <p>VERA BRADELY VR</p>
                                        <p class="description">Unity, Daydream, VR, Android</p>
                                    </div>
                                </a>
                            </li>

                            <li>
                                <a target="_blank" href="https://shopobsess.co/">
                                    <img src={img8} alt="Portfolio item" />
                                    <div class="text">
                                        <p>SHOP OBSESS</p>
                                        <p class="description">React, three.js</p>
                                    </div>
                                </a>
                            </li>

                            <li>
                                <a target="_blank" href="https://applixya.com/solutions/iapproval/">
                                    <img src={img10} alt="Portfolio item" />
                                    <div class="text">
                                        <p>iApproval</p>
                                        <p class="description">Objective-C, PDFKit, Document Imaging</p>
                                    </div>
                                </a>
                            </li>

                            <li>
                                <a>
                                    <img src={img11} alt="Portfolio item" />
                                    <div class="text">
                                        <p>EV Tourism</p>
                                        <p class="description">iOS, MapKit, Swift 4.2</p>
                                    </div>
                                </a>
                            </li>

                        </ul>

                        <a target="_blank" href="https://itunes.apple.com/us/developer/yalla-games-inc/id718115198?mt=8"><div class="read-more">Load more</div></a>

                    </div>
                </section>

                <div class="clear"></div>
            </React.Fragment>
        );
    }
}

export default Portfolio;