import React, { Component } from 'react';

class Header extends Component {
    render() {
        return (
            <section class="start-page parallax-background" id="home">

                <div class="opacity"></div>
                <div class="content">
                    <div class="text">

                        <h1>Basel Abdelaziz<br />
                            <span>iOS/Mobile Engineer</span></h1>

                        <a href="#about-us"><div class="read-more">Read more</div></a>

                    </div>
                    <div class="arrow-down"></div>
                </div>

            </section>
        );
    }
}

export default Header;