import React, { Component } from 'react';
import { Route } from "react-router-dom";
import HomePage from './pages/HomePage';
import IOSApps from './pages/IOSApps';
import UnityGames from './pages/UnityGames';

class Routes extends Component {
    render() {
        return (
            <React.Fragment>
                <Route
                    path={'/'}
                    exact
                    render={() => <HomePage />}
                />
                <Route
                    path={'/ios'}
                    exact
                    render={() => <IOSApps />}
                />
                <Route
                    path={'/unity'}
                    exact
                    render={() => <UnityGames />}
                />
            </React.Fragment>
        );
    }
}

export default Routes;