
import React, { Component } from 'react';
import Header from './Header';
import Menu from './Menu';
import AboutUs from './AboutUs';
import Portfolio from './Portfolio';
import Contact from './Contact';
import Footer from './Footer';

class HomePage extends Component {
    render() {
        return (
            <React.Fragment>
                <Header />
                <Menu />
                <AboutUs />
                <Portfolio />
                <Contact />
                <Footer />
            </React.Fragment>
        );
    }
}

export default HomePage;