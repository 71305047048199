import React, { Component } from "react";

import resume from '../img/pdf/Basel-Resume-no-phone.pdf';

class Footer extends Component {
    render() {
        return (
            <React.Fragment>
                <section class="footer">
                    <div class="margin">
                        <div class="menu-footer">
                            <a href="#home">Home</a>
                            <a href="https://www.linkedin.com/in/basel-abdelaziz/">Linkedin</a>
                            <a target="_blank" href="https://github.com/iBasel">Github</a>
                            <a href={resume} download>CV</a>
                        </div>
                    </div>
                </section>

            </React.Fragment>
        );
    }
}

export default Footer;