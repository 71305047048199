import React, { Component } from "react";

class Contact extends Component {
    render() {
        return (
            <React.Fragment>
                <section class="contact" id="contact">
                    <h1>Contact</h1>
                    <hr />
                    <div class="content">
                        <div class="contact-text">
                            Please feel free to send me an <a href="mailto:basel.e@me.com"><strong>email</strong></a> for any inquiries<br /><br />                            
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default Contact;